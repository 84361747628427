.wrapper {
  width: 100%;
  /* min-height: 100vh; */
  /* background: linear-gradient(
    to bottom,
    var(--thm-base) 10px,
    #f9f8ff 60%,
    var(--thm-base-hover)
  ); */
  margin: 0 auto;
  overflow: hidden;
}

.step {
  width: 15rem;
  height: 0.75rem;
  display: inline-block;
}
.step:not(:last-child) {
  margin-right: 3rem;
}
.step.active {
  background-color: #ffc533 !important;
}
.step.finish {
  background-color: #ffc533 !important;
}

.multisteps_form_panel {
  display: none;
}

.question_number {
  padding-top: 5rem;
}

.quiz-ul > .question-li {
  background-color: #f4f4f4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* span {
  color: #ffc533;
  font-size: 1.25rem;
  font-weight: 700;
  padding-bottom: 1rem;
  -webkit-transition: 0.8s cubic-bezier(0.25, 1, 0.5, 1);
  transition: 0.8s cubic-bezier(0.25, 1, 0.5, 1);
} */

h1 {
  color: #000a38;
  font-size: 4.204375rem;
}

.result-panel {
  margin-top: 3rem !important;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

li.step_2 {
  width: 100%;
  max-width: 38vw;
  color: #000a38 !important;
  font-size: 1.1rem !important;
  padding: 0.9375rem 3rem !important;
  margin-top: 1.25rem !important;
  position: relative !important;
  cursor: pointer !important;
  border-radius: 14px;
}

li.step_2:hover {
  border: 1px solid #32de84 !important;
}
li.step_2.right-option {
  background-color: #32de84 !important;
  color: white !important;
}
li.step_2.wrong-option {
  background-color: #ef0107 !important;
  color: white !important;
}
li.step_2 label {
  cursor: pointer;
}

li.step_2 label:hover {
  color: white;
}
li.active:before {
  display: block;
}

.f_btn {
  font-size: 1.25rem;
  font-weight: 800;
  padding: 0.9375rem 1.5625rem;
  outline: none;
  margin-right: 0.625rem;
  margin-bottom: 2rem;
  -webkit-transition: 0.8s cubic-bezier(0.25, 1, 0.5, 1);
  transition: 0.8s cubic-bezier(0.25, 1, 0.5, 1);
}

body {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.65;
  font-style: normal;
  font-display: swap;
  font-family: "Jost", sans-serif;
  background-color: #ffffff;
}

a,
button,
input {
  cursor: pointer;
  text-decoration: none;
}
a:hover,
button:hover,
input:hover {
  text-decoration: none;
}
a:focus,
button:focus,
input:focus {
  outline: none;
}

.quiz-ul {
  display: flex;
  justify-content: space-between;
  align-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;
}

ul > li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;
}

.animate_50ms {
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms;
}

.animate_100ms {
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
}

.animate_150ms {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
}

.animate_200ms {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}

.animate_250ms {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms;
}

@media (min-width: 1500px) {
  .container {
    max-width: 1510px;
  }
}
/* ==================================================
*   01 - media screen and (max-width: 1500px)
*   02 - media screen and (max-width: 1400px)
*	03 - media screen and (max-width: 1200px)
*	04 - media screen and (max-width: 992px)
*	05 - media screen and (max-width: 768px)
*	06 - media screen and (max-width: 576px)
*	07 - media screen and (max-width: 380px)
*   XX-Large devices (larger desktops)
*   No media query since the xxl breakpoint has no upper bound on its width
================================================== */
@media screen and (max-width: 1499.98px) {
  .step_progress .step {
    width: 12rem;
  }
  .step_progress .step:not(:last-child) {
    margin-right: 2.8rem;
  }
}
@media screen and (max-width: 1399.98px) {
  .step_progress .step {
    width: 12rem;
  }
  .step_progress .step:not(:last-child) {
    margin-right: 2.8rem;
  }

  /* span {
    font-size: 1.2rem;
    padding-bottom: 0.8rem;
  } */

  h1 {
    font-size: 4rem;
  }

  .form_items li {
    font-size: 1.2rem;
    padding: 0.8375rem 3rem;
  }

  .f_btn {
    font-size: 1.18rem;
    font-weight: 700;
    padding: 0.7375rem 1.3625rem;
  }
}
@media screen and (max-width: 1199.98px) {
  .step_progress .step {
    width: 10rem;
  }
  .step_progress .step:not(:last-child) {
    margin-right: 2.6rem;
  }

  /* span {
    font-size: 1.15rem;
    padding-bottom: 0.6rem;
  } */

  h1 {
    font-size: 3.5rem;
  }

  .form_items li {
    font-size: 1rem;
    padding: 0.7375rem 3rem;
  }

  .f_btn {
    font-size: 1.15rem;
    font-weight: 700;
    padding: 0.8375rem 1.4625rem;
  }
}
@media screen and (max-width: 991px) {
  .step_progress .step {
    width: 6.5rem;
  }
  .step_progress .step:not(:last-child) {
    margin-right: 2rem;
  }

  /* span {
    font-size: 1rem;
  } */

  h1 {
    font-size: 3.5rem;
    font-weight: 600;
  }

  .form_items li {
    font-size: 1rem;
  }

  .f_btn {
    font-size: 1rem;
    font-weight: 600;
    padding: 0.7375rem 1.3625rem;
  }
}
@media screen and (max-width: 767px) {
  .step_progress .step {
    width: 5rem;
  }
  .step_progress .step:not(:last-child) {
    margin-right: 1.5rem;
  }

  li.step_2 {
    width: 100%;
    max-width: 100vw;
  }

  /* span {
    font-size: 0.8rem;
  } */

  h1 {
    font-size: 2.5rem;
    font-weight: 500;
  }

  .form_items li {
    font-size: 0.8rem;
  }

  .f_btn {
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0.5375rem 1.1625rem;
  }
}
@media screen and (max-width: 575px) {
  /* span {
    font-size: 0.8rem;
  } */

  h1 {
    font-size: 2rem;
    font-weight: 500;
  }

  .f_btn {
    font-size: 0.6rem;
    padding: 0.5375rem 1.1625rem;
  }
}
