#calendar {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	margin: 0 auto;
	overflow: hidden;
	border-radius: 0.25rem;
}

.calendar-background {
	display: flex;
	background: white;
	padding: 33px;
	background: radial-gradient(circle, #eeaeca 0%, #94bbe9 100%);
}

.header {
	height: 50px;
	/* width: 420px; */
	/* background: rgba(66, 66, 66, 1); */
	text-align: center;
	position: relative;
	z-index: 100;
}

.header h1 {
	margin: 0;
	padding: 0;
	font-size: 20px;
	line-height: 50px;
	font-weight: 100;
	letter-spacing: 1px;
}

.left,
.right {
	position: absolute;
	width: 0px;
	height: 0px;
	border-style: solid;
	top: 50%;
	margin-top: -7.5px;
	cursor: pointer;
}

.left {
	border-width: 7.5px 10px 7.5px 0;
	border-color: transparent rgba(160, 159, 160, 1) transparent transparent;
	left: 20px;
}

.right {
	border-width: 7.5px 0 7.5px 10px;
	border-color: transparent transparent transparent rgba(160, 159, 160, 1);
	right: 20px;
}

.month {
	/*overflow: hidden;*/
	opacity: 0;
}

.month.new {
	-webkit-animation: fadeIn 1s ease-out;
	opacity: 1;
}

.month.in.next {
	-webkit-animation: moveFromTopFadeMonth 0.4s ease-out;
	-moz-animation: moveFromTopFadeMonth 0.4s ease-out;
	animation: moveFromTopFadeMonth 0.4s ease-out;
	opacity: 1;
}

.month.out.next {
	-webkit-animation: moveToTopFadeMonth 0.4s ease-in;
	-moz-animation: moveToTopFadeMonth 0.4s ease-in;
	animation: moveToTopFadeMonth 0.4s ease-in;
	opacity: 1;
}

.month.in.prev {
	-webkit-animation: moveFromBottomFadeMonth 0.4s ease-out;
	-moz-animation: moveFromBottomFadeMonth 0.4s ease-out;
	animation: moveFromBottomFadeMonth 0.4s ease-out;
	opacity: 1;
}

.month.out.prev {
	-webkit-animation: moveToBottomFadeMonth 0.4s ease-in;
	-moz-animation: moveToBottomFadeMonth 0.4s ease-in;
	animation: moveToBottomFadeMonth 0.4s ease-in;
	opacity: 1;
}

.week {
	/* background: #4a4a4a; */
}

.day {
	display: inline-block;
	width: 60px;
	text-align: center;
	vertical-align: top;
	cursor: pointer;
	position: relative;
	z-index: 100;
}

.day.other {
	color: rgba(255, 255, 255, 0.3);
}

.day.today {
	color: var(--thm-base-hover);
}

.day-number {
	border-radius: 50%;
	height: 36px;
	padding: 8px;
	text-align: center;
	font-size: 13px;
}

.today .day-number {
	border-radius: 50%;
	background: var(--thm-base);
	color: white;
	height: 36px;
	padding: 8px;
	background: var(--thm-base);
	color: white;
	text-align: center;
	font-size: 13px;
}

.day-name {
	font-size: 9px;
	text-transform: uppercase;
	margin-bottom: 5px;
	letter-spacing: 0.7px;
}

.day-number {
	margin: 5px 11px;
}

.day-number:hover {
	border-radius: 50%;
	background: var(--thm-base-hover);
	color: white;
}

.day .day-events {
	list-style: none;
	/* margin-top: 3px; */
	text-align: center;
	height: 12px;
	line-height: 6px;
	overflow: hidden;
}

.day .day-events span {
	vertical-align: top;
	display: inline-block;
	padding: 0;
	margin: 0;
	width: 24px;
	height: 5px;
	line-height: 5px;
	margin: 0 1px;
}

.blue {
	background: rgba(156, 202, 235, 1);
}
.orange {
	background: var(--thm-base);
}
.green {
	background: rgba(153, 198, 109, 1);
}
.dark-blue {
	background: var(--thm-base-hover);
}

.first-color {
	background: #fde9df;
}

.second-color {
	background: #ffd6a4;
}

.third-color {
	background: var(--thm-base);
}

.fourth-color {
	background: var(--thm-base-hover);
}

.details {
	position: relative;
	/* width: 420px; */
	/* height: 75px; */
	/* background: rgba(164, 164, 164, 1); */
	margin-top: 5px;
	border-radius: 4px;
	--tw-bg-opacity: 1;
	background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.large-details {
	position: relative;
	border-radius: 4px;
	--tw-bg-opacity: 1;
	background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.large-event {
	font-size: 11px;
}

.details.in {
	-webkit-animation: moveFromTopFade 0.5s ease both;
	-moz-animation: moveFromTopFade 0.5s ease both;
	animation: moveFromTopFade 0.5s ease both;
}

.details.out {
	-webkit-animation: moveToTopFade 0.5s ease both;
	-moz-animation: moveToTopFade 0.5s ease both;
	animation: moveToTopFade 0.5s ease both;
}

.arrow {
	position: absolute;
	top: -5px;
	left: 50%;
	margin-left: -2px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 0 5px 5px 5px;
	border-color: transparent transparent rgba(164, 164, 164, 1) transparent;
	transition: all 0.7s ease;
}

.events {
	padding: 0.3rem 0.4rem;
	overflow-y: auto;
	overflow-x: hidden;
}

.events.in {
	-webkit-animation: fadeIn 0.3s ease both;
	-moz-animation: fadeIn 0.3s ease both;
	animation: fadeIn 0.3s ease both;
}

.events.in {
	-webkit-animation-delay: 0.3s;
	-moz-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

.details.out .events {
	-webkit-animation: fadeOutShrink 0.4s ease both;
	-moz-animation: fadeOutShink 0.4s ease both;
	animation: fadeOutShink 0.4s ease both;
}

.events.out {
	-webkit-animation: fadeOut 0.3s ease both;
	-moz-animation: fadeOut 0.3s ease both;
	animation: fadeOut 0.3s ease both;
}

.event {
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.5px;
	padding: 2px 16px;
	vertical-align: top;
	padding: 13px;
	border-bottom-width: 1px;
	border-left: 0px;
	border-right: 0px;
	--tw-border-opacity: 1;
	border-style: dashed;
	border-color: rgb(156 163 175 / var(--tw-border-opacity));
	border-top: 0px;
}

.event-category {
	height: 10px;
	width: 10px;
	display: inline-block;
	margin: 6px 0 0;
	vertical-align: top;
}

.event span {
	display: inline-block;
	padding: 0 0 0 7px;
}

.legend {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 30px;
	/* background: rgba(60, 60, 60, 1); */
	line-height: 30px;
}

.entry {
	position: relative;
	padding: 0 0 0 25px;
	font-size: 13px;
	display: inline-block;
	line-height: 30px;
	background: transparent;
}

.entry:after {
	position: absolute;
	content: '';
	height: 5px;
	width: 5px;
	top: 12px;
	left: 14px;
}

.entry.blue:after {
	background: rgba(156, 202, 235, 1);
}
.entry.orange:after {
	background: rgba(247, 167, 0, 1);
}
.entry.green:after {
	background: rgba(153, 198, 109, 1);
}
.entry.yellow:after {
	background: rgba(249, 233, 0, 1);
}

/* Animations are cool!  */
@-webkit-keyframes moveFromTopFade {
	from {
		opacity: 0.3;
		height: 0px;
		margin-top: 0px;
		-webkit-transform: translateY(-100%);
	}
}
@-moz-keyframes moveFromTopFade {
	from {
		height: 0px;
		margin-top: 0px;
		-moz-transform: translateY(-100%);
	}
}
@keyframes moveFromTopFade {
	from {
		height: 0px;
		margin-top: 0px;
		transform: translateY(-100%);
	}
}

@-webkit-keyframes moveToTopFade {
	to {
		opacity: 0.3;
		height: 0px;
		margin-top: 0px;
		opacity: 0.3;
		-webkit-transform: translateY(-100%);
	}
}
@-moz-keyframes moveToTopFade {
	to {
		height: 0px;
		-moz-transform: translateY(-100%);
	}
}
@keyframes moveToTopFade {
	to {
		height: 0px;
		transform: translateY(-100%);
	}
}

@-webkit-keyframes moveToTopFadeMonth {
	to {
		opacity: 0;
		-webkit-transform: translateY(-30%) scale(0.95);
	}
}
@-moz-keyframes moveToTopFadeMonth {
	to {
		opacity: 0;
		-moz-transform: translateY(-30%);
	}
}
@keyframes moveToTopFadeMonth {
	to {
		opacity: 0;
		-moz-transform: translateY(-30%);
	}
}

@-webkit-keyframes moveFromTopFadeMonth {
	from {
		opacity: 0;
		-webkit-transform: translateY(30%) scale(0.95);
	}
}
@-moz-keyframes moveFromTopFadeMonth {
	from {
		opacity: 0;
		-moz-transform: translateY(30%);
	}
}
@keyframes moveFromTopFadeMonth {
	from {
		opacity: 0;
		-moz-transform: translateY(30%);
	}
}

@-webkit-keyframes moveToBottomFadeMonth {
	to {
		opacity: 0;
		-webkit-transform: translateY(30%) scale(0.95);
	}
}
@-moz-keyframes moveToBottomFadeMonth {
	to {
		opacity: 0;
		-webkit-transform: translateY(30%);
	}
}
@keyframes moveToBottomFadeMonth {
	to {
		opacity: 0;
		-webkit-transform: translateY(30%);
	}
}

@-webkit-keyframes moveFromBottomFadeMonth {
	from {
		opacity: 0;
		-webkit-transform: translateY(-30%) scale(0.95);
	}
}
@-moz-keyframes moveFromBottomFadeMonth {
	from {
		opacity: 0;
		-webkit-transform: translateY(-30%);
	}
}
@keyframes moveFromBottomFadeMonth {
	from {
		opacity: 0;
		-webkit-transform: translateY(-30%);
	}
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
}
@-moz-keyframes fadeIn {
	from {
		opacity: 0;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
}

@-webkit-keyframes fadeOut {
	to {
		opacity: 0;
	}
}
@-moz-keyframes fadeOut {
	to {
		opacity: 0;
	}
}
@keyframes fadeOut {
	to {
		opacity: 0;
	}
}

@-webkit-keyframes fadeOutShink {
	to {
		opacity: 0;
		padding: 0px;
		height: 0px;
	}
}
@-moz-keyframes fadeOutShink {
	to {
		opacity: 0;
		padding: 0px;
		height: 0px;
	}
}
@keyframes fadeOutShink {
	to {
		opacity: 0;
		padding: 0px;
		height: 0px;
	}
}

@media (max-width: 700px) {
	#calendar {
		width: 85vw;
	}

	.day {
		width: calc(100% / 7);
	}

	.day-number {
		margin: 6px 7px;
		width: 36px;
	}

	.large-details {
		display: none;
	}

	.large-date {
		display: none;
	}
}

@media screen and (min-width: 700px) {
	#calendar {
		width: 100vw;
		max-width: 1199px;
	}
	.month.in.week {
		display: flex;
		flex-direction: column;
		border: 2px solid #fff;
	}

	.week {
		flex: 1 1 0%;
		display: flex;
	}

	.day {
		width: calc(100% / 7);
		margin: 6px 4px;
		/* box-shadow:
      rgba(0, 0, 0, 0.16) 1px 0.5px 0px,
      rgb(51, 51, 51) 0px 0px 0.5px 0px; */
		cursor: auto;
		/* border: 1px solid white; */
		/* border-radius: 4px; */
		min-height: 119px;
		background: rgba(255, 255, 255, 0.25);
		backdrop-filter: blur(4px);
		-webkit-backdrop-filter: blur(4px);
		border-radius: 10px;
		border: 1px solid rgba(255, 255, 255, 0.18);
	}

	.day-events {
		display: none !important;
	}

	.day-name {
		padding-top: 10px;
		font-size: 13px;
	}

	.day-number {
		width: initials;
		text-align: center;
		font-size: 15px;
	}

	.large-details {
		display: inline;
	}

	.large-event span {
		font-weight: 900;
	}

	.details {
		display: none;
	}

	.today .day-number {
		background: none;
		color: black;
	}

	.day-number:hover {
		background: none;
		color: black;
	}

	.day-events {
		display: none !important;
	}

	.day-name,
	.day-number {
		display: none;
	}

	.large-date {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		padding: 3px 8px;
	}

	.day.today {
		color: white;
		background-color: #6e66fb;
	}

	.day.hide-date {
		box-shadow: none;
		border: none;
		background: none;
		backdrop-filter: none;
		-webkit-backdrop-filter: none;
	}

	.border-blue {
		border: 1px solid var(--thm-base-hover);
	}

	.large-date div {
		font-size: 1.5rem;
		font-weight: 500;
	}
}

form {
	margin: 3rem auto 0;
	width: 24rem;
	margin-bottom: 20px !important;

	div {
		width: inherit;
	}

	label {
		display: block;
		color: #000000;
	}

	input,
	select,
	textarea {
		margin: 0.5rem 0;
		padding: 0.5rem;
		width: inherit;
		height: 2rem !important;
		border: none;
		border-radius: 4px;
		box-sizing: border-box;
	}

	input[type='submit'] {
		height: 2.5rem !important;
		font-size: 1rem;
		color: #fff;
		background: #0971f1;
		cursor: pointer;
	}

	textarea {
		height: 5rem;
	}
}
